<template>
    <div class="box">
        <div class="box-div1 box" @mousemove="showCate(0)">
            <span class="fs-16 pad-l-30 pad-r-15" :class="widthType == 1 ? 'show-block fc-17d':'fc-333'">
                <i-mail v-if="widthType == 1" fill="#17d" theme="filled" size="16px"/> 
                <i-mail v-if="widthType != 1" fill="#333" theme="filled" size="16px"/> 
                Email: <a class="fs-16 over-17d" :class="widthType == 1 ? 'fc-17d':'fc-333'" :href="'mailto:'+email">{{ email }}</a>
            </span>
            <span class="fs-16 pad-l-30" :class="widthType == 1 ? 'show-block fc-17d':'fc-333'">
                <i-phone-telephone v-if="widthType == 1" fill="#17d" theme="filled" size="16px"/> 
                <i-phone-telephone v-if="widthType != 1" fill="#333" theme="filled" size="16px"/> 
                Phone: <a class="fs-16 over-17d" :class="widthType == 1 ? 'fc-17d':'fc-333'" :href="'tel:'+phone">{{ phone }}</a>
            </span>
        </div>
        <div class="box" :class="{'fixed':fixed}">
            <div class="box-div2 box" :class="fixed ? 'div-h55':'div-h75'">
                <div class="logo-div">
                    <img v-if="widthType != 1" src="../assets/images/head/logo.png" :class="fixed ? 'logo-50' : 'logo-70'"/>
                    <img v-if="widthType == 1" src="../assets/images/head/logo.png" :class="fixed ? 'logo-40' : 'logo-60'"/>
                </div>
                <div class="menu-div">
                    <div v-if="widthType == 2">
                        <span v-for="(item,index) in menuList" :key="index" class="fw-bold" > 
                            <span  class="over-b-line pad-lr-15 over-pointer over-17d fs-16" 
                            :class="[curPage == item.id ? 'fc-17d sel-b-line': 'fc-333', fixed ? 'pad-tb-12':'pad-tb-22']"
                            @mousemove="showCate(item.id)"  @click="changePage(item.id)">
                                {{item.name}}
                                <span v-if="item.id == 3">
                                    <i-down-one v-if="hoverId == item.id || curPage == item.id" fill="#17d" theme="filled" size="16px"></i-down-one>
                                    <i-down-one v-if="hoverId != item.id && curPage != item.id" fill="#333" theme="filled" size="16px"></i-down-one>
                                </span>
                            </span>
                        </span>
                        <span class="over-pointer pad-l-15 pad-r-50" @click="showSearch()"><i-search theme="outline" size="16" fill="#333" /></span>
                        <searchC v-if="!searchClose"></searchC> 
                    </div>
                    <div v-if="widthType != 2">
                        <div class="fold-menu">
                            <span class="over-pointer"  @click="showSearch()">
                                <i-search theme="outline" size="26" fill="#17D" />
                            </span>
                            <span class="over-pointer pad-lr-30" @click="showMenu()">
                                <i-vertical-tidy-up theme="filled" size="26" fill="#17D"/>
                            </span>
                        </div>
                        <searchC v-if="!searchClose"></searchC> 
                    </div>
                </div>
            </div>
            <transition name="fade1">
                <productCateC v-if="!cateClose" @transmit="getMessage"></productCateC>
            </transition>
            
            <transition name="fade">
                <div class="menu-type2" id="menu-type2" v-if="!menuClose">
                    <div class="h-space-10"></div>
                    <span v-for="(item,index) in menuList" :key="index" @mouseenter="changeHoverId(item.id)" >
                        <span class="one-menu-line" :id="item.id" :class="curPage == item.id ? 'curLine' : ''" @click="changePage(item.id)">
                            {{item.name}}
                            <span v-if="item.id == 3" class="fold-cate"  @click.stop="changeFold()">
                                <i-down v-if="(hoverId == 3 || curPage == 3) && foldStatus" fill="#FFF" theme="filled" size="30px"></i-down>
                                <i-down v-if="hoverId != 3 && curPage != 3  && foldStatus" fill="#17d" theme="filled" size="30px"></i-down>
                                <i-up v-if="(hoverId == 3 || curPage == 3)  && !foldStatus" fill="#FFF" theme="filled" size="30px"></i-up>
                                <i-up v-if="hoverId != 3 && curPage != 3  && !foldStatus" fill="#17d" theme="filled" size="30px"></i-up>
                            </span>
                        </span>
                        <transition name="fade2">
                            <div v-if="foldStatus && item.id == 3" class="menu-type3" >
                                <span v-for="item2 in category" :key="item2.id" class="one-menu-line pad-l-15"
                                @mousemove.stop="changeHoverId(-1)" @click="goProducts(item2.id)">
                                    {{ item2.name }}
                                </span>
                            </div>
                        </transition>
                    </span>
                </div>
            </transition>
        </div>
        <div class="box-div3" v-if="fixed"></div>
        <connectC></connectC>
    </div>
</template>

<script>
import searchC from './searchC.vue'
import connectC from './connectC.vue'
import productCateC from './productCateC.vue'
export default ({
    name: 'headC',
    components:{
        searchC,
        connectC,
        productCateC,
    },
    props: {
        myId: Number
    },
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            phone: this.CONSTANT.phone,
            email: this.CONSTANT.email,
            menuList: this.CONSTANT.menuList,
            fixed: false,
            menuClose: true,
            searchClose: true,
            curPage: this.myId,
            hoverId: 0,
            cateClose: true,
            foldStatus: false,
            category: this.PRODUCT.productCategory,
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },
        handleScroll () {
            var scrollTop = 0;
            if (document.documentElement && document.documentElement.scrollTop) {
                scrollTop = document.documentElement.scrollTop;
            } else if (document.body) {
                scrollTop = document.body.scrollTop;
            }
            this.fixed = scrollTop >= 49 ? true : false;
        },
        changePage(num){
            this.menuClose = true;
            window.scrollTo(0,0);
            if(num == 1){
                this.$router.push('/home')
            }else if(num == 2){
                this.$router.push('/about')
            }else if(num == 3){
                this.$router.push('/products')
            }else if(num == 4){
                this.$router.push('/news')
            }else if(num == 5){
                this.$router.push('/contact')
            }
        },
        showMenu () {
            this.menuClose = !this.menuClose;
            if(this.menuClose){
                this.foldStatus = false;
            }
        },
        showSearch () {
            this.searchClose = !this.searchClose;
        },
        showCate(num){
            this.hoverId = num;
            if(num == 3){
                this.cateClose = false;
            }else{
                this.cateClose = true;
            }
        },
        changeHoverId(mun){
            this.hoverId = mun;
            console.log("this.curPage="+this.curPage+",this.hoverId="+this.hoverId+",this.foldStatus="+this.foldStatus)
        },
        getMessage(data){
            this.cateClose = data;
        },
        changeFold(){
            this.foldStatus = !this.foldStatus;
            if(this.foldStatus){
                document.getElementById("menu-type2").style.height = '290px';
            }else{
                document.getElementById("menu-type2").style.height = '190px';
            }
        },
        goProducts (cateId){
            window.scrollTo(0,0);
            console.log("GO ROUTE PRODUCT "+cateId)
            this.$router.push({
                name:'products',
                params:{
                    pName:cateId,
                }
            });
        },
        
    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
        window.addEventListener('scroll', this.handleScroll);
    },
})

</script>

<style scoped>

.box-div1{
    line-height: 24px;
    font-size: 16px;
    padding: 12px 0px;
    border-bottom: 1px solid #CCC;
}

.box-div2{
    display: flex;
    
}
.div-h75{
    height: 75px;
}
.div-h55{
    height: 55px;
}
.fixed {
    background-color: #FFF;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.logo-div{
    margin-left: 25px;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
}
.menu-div{
    flex:1;           
    justify-content: flex-end;
    display: flex;
    align-items: center;
}
.fold-menu{
    line-height: 100%;
    align-items: center;
}

.menu-type2 {
    width: 96%;
    margin-left: 2%;
    height: 190PX;
    transition: height .8s;
    overflow: hidden;
}
.menu-type3{
    overflow: hidden;
}
.h-space-10{
    width: 1px;
    height: 10px;
}
.one-menu-line{
    height: 30px;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    cursor: pointer;
    color: #17D;
    border-bottom: 1px solid rgb(219, 216, 216);
    padding: 1px;
}
.one-menu-line:hover, .curLine{
    background-color: #17D;
    color: #FFFFFF;
}
.fold-cate{
    position: absolute;
    right:30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: max-height 0.8s ease;
  max-height: 290px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
  overflow: hidden;
}

.fade1-enter-active,
.fade1-leave-active {
    transition: opacity .8s ease;
}

.fade1-enter,
.fade1-leave-to {
    opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: max-height .8s ease;
  max-height: 100px;
}

.fade2-enter,
.fade2-leave-to {
  max-height: 0;
  overflow: hidden;
}

.box-div3{
    height: 55px;
    width: 100%;
}


</style>