<template>
    <div>
        <headC myId=0></headC>
        <navigationC name="search"></navigationC>
        <div class="productArea">
            <div class="title-div" >
                    <span>PRODUCTS</span>
                </div>
                <div v-for="item in curList" :key="item.name" class="product-item" >
                    <img :src=item.icon class="product-img" :class="item.name == curProduct ? 'shadow-img' : ''" 
                    @mouseover="showRight(item.name)" @mouseout="showRight('')" @click="goDetail(item.name)">
                    <i-right-small v-if="item.name == curProduct" size="30px" fill="#007ACC" class="icon-right" @click="goDetail(item.name)"/>
                    <span class="product-name" @click="goDetail(item.name)"
                    @mouseover="showRight(item.name)" @mouseout="showRight('')">{{ item.name }}</span>
                </div>
                <div v-if="curList.length ==0" class="empty-product">
                    <span class="title">Nothing Found</span>
                    <span class="content">Sorry, but nothing matched your search terms. Please try again with different keywords.</span>
                </div>
        </div>
        <footC></footC>
    </div>

</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'
export default ({
    name: 'searchV',
    components: {
        headC,
        footC,
        navigationC,
    },
    data() {
        return {
            companyName: this.CONSTANT.companyName,
            category: this.PRODUCT.productCategory,
            productList: this.PRODUCT.productList,
            curList: Object,
            curProduct: '',
            queryName: '',
        };
    },
    watch: {
		'$route.query.name': {
			immediate: true,
            deep: true,
			handler: function(name) {
				if(name != undefined){
                   this.queryName = name; 
                   this.initProductList();
                }
			}
		},
	},
    methods:{
        showRight(name){
            this.curProduct = name;
        },
        initProductList(){
            var filterList = [];
            for (var i = 0; i < this.productList.length; i++) {
                var curList = this.productList[i];
                for (var j = 0; j < curList.length; j++) {
                    var curObj = curList[j];
                    if(curObj.name.toUpperCase().includes(this.queryName.toUpperCase())){
                        filterList.unshift(curObj);
                    }
                } 
            }
            this.curList = filterList;
        },
        goDetail (productName) {
            this.$router.push({
                name:'productDetail',
                query:{
                    name:productName,
                },
            });
        }
    },
    mounted: function(){
        document.title= this.companyName + " | search";
    },

})
   
</script>
<style scoped>
.productArea{
    width:96%;
    margin-right: 2%;
    float:right;
}

.title-span{
    display: block;
    font-size: 20px;
    color:#333333;
    line-height: 55px;
    border-bottom: 2px solid #CCCCCC;
}


.title-div{
    width:97%;
    margin-left: 3%;
    font-size: 20px;
    color:#333333;
    line-height: 55px;
    border-bottom: 2px solid #CCCCCC;
    margin-bottom: 20px;
}
.product-item{
    position: relative;
    float: left;
    margin-left: 4%;
    width:20%;
    margin-bottom: 40px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    color: #333333;
}
.product-name:hover{
    cursor: pointer;
    color: #007ACC;
}

.product-img{
    width: 100%;
}
.product-img:hover{
    width: 100%;
    cursor: pointer;
}
.shadow-img{
    box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
}
.icon-right{
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    right:10px;
    top: 10px; 
}
.icon-right:hover{
    cursor: pointer;
}
.empty-product{
    width: 94%;
    margin-left: 3%;
    background-color: #D1ECF1;
}
.title{
    display: block;
    margin-left: 2%;
    font-size: 20px;
    color:#333333;
    line-height: 55px;
}
.content{
    display: block;
    margin-left: 2%;
    font-size: 16px;
    color:#333333;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 45px;
}


</style>