<template>
    <div class="box">
        <headC></headC>
    
        <div class="content">
            <div v-if="!shrink" class="time-left">
                <span class="tl-span1">{{ curNews.dateYear }}</span>
                <span class="tl-span2">{{ curNews.dateDay }}</span>
            </div>
            <div class="news-right">
                <div class="news-title">
                    <div v-if="shrink" class="time-right">
                        <span class="t2-span1">{{ curNews.dateYear }}</span>
                        <span class="t2-span2">{{ curNews.dateDay }}</span>
                    </div>
                    <div :class="shrink ? 'news-title-text-shirnk' : 'news-title-text' ">
                        <h2>{{ curNews.title }}</h2>
                    </div>
                </div>

                <hr class="line" />

                <div class="news-detail-text">
                    <span>12121212121212 </span>
                </div>

                <div class="share-news">
                    <h3>Share this post</h3>
                    <div v-for="(item,index) in shareList" :key="index" :title="item.title" class="one-contact over-pointer" 
                        :class="item.title" @click="openUrl(item.url)">
                        <Icon :icon="item.name" width="24px" height="24px" color="#FFF"/>
                    </div>
                </div>

                <div class="empty-line"></div>
                <hr class="line"/>

                <div class="other-news">
                    <div v-if="curNews.prevPath != ''">
                        <span class="other-sub-title">Previous</span>
                        <a><span>{{ curNews.prevTitle }}</span></a>
                    </div>
                    <div v-if="curNews.nextPath != ''">
                        <span class="other-sub-title">Next</span>
                        <a><span>{{ curNews.nextTitle }}</span></a>
                    </div>
                </div>
            </div>
        </div>
        <footC></footC>
    </div>

</template>

<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import { Icon } from '@iconify/vue2';
export default ({
    name: 'newsDetailV1',
    components:{
        headC,
        footC,
        Icon,
    },
    data() {
        return {
            shrink: false,
            companyName: this.CONSTANT.companyName,
            detailList: this.NEWS.detailList,
            curNews: this.NEWS.newsNO1,
            shareList: this.NEWS.shareList,
        };
    },
    // beforeDestroy: function () {
    //   window.removeEventListener('resize', this.handleResize)
    // },
    methods:{
        handleResize() {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 840 ? true : false;
        },
    },
    mounted: function(){
        document.title= this.companyName + " | newsDatail";
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
.box{
    width: 100%;
    word-break: break-word;
    white-space: pre-wrap;
}

.content{
    background-color: #EFEFEF;
    width: 90%;
    padding: 30px 5% 30px 5%;
    display: flex;
}
.time-left{
    width: 120px;
    height: 120px;
    background-color: #007ACC;
    color: white;
    text-align: center;
    margin-top: 5px;
}
.tl-span1{
    display:block;
    font-size: 20px;
    padding-top: 15px;
}
.tl-span2{
    display:block;
    font-size: 48px;
}

.news-right{
    flex: 1;
    width: 94%;
    margin-left: 3%;
}
.news-title{
    display: flex;
    padding-bottom: 20px;
}
.time-right{
    width: 75px;
    height: 75px;
    background-color: #007ACC;
    color: white;
    text-align: center;
    margin-right: 3%;	
    margin-top: 5px;
}
.t2-span1{
    display: block;
    font-size: 12px;
    padding-top: 10px;
}
.t2-span2{
    display: block;
    font-size: 30px;
}

.news-title-text{
    flex: 1;
    font-size: 32px;
    color: black;
    line-height: 46px;
}

.news-title-text-shirnk{
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    color: black;
}

.news-detail-text{
    font-size: 14px;
    color: #656565;
}

.empty-line{
    display: block;
    width: 100%;
    height: 40px;
}
.line{
	display: block;	
    margin-top: 10px;
    margin-bottom: 30px;
}

.share-news{
    margin-top: 30px;
    width: 100%;
    font-size: 16px;
}
.share-news h3{
    display: block;
    margin-bottom: 20px;
}

.one-contact{
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    text-align: center;
    padding-top: 3px;
}

.Facebook{
    background-color: #5595EA;
}
.LinkedIn{
    background-color: #3B67BB;
}
.Youtube{
    background-color: #E13723;
}
.Instagram{
    background-color: #724C3D;
}
.WeChat{
    background-color: #1CD46C;
}
.WhatsApp{
    background-color: #78C468;
}

.other-news{
    width: 100%;
    margin-top: 30px;
}

.other-sub-title{
    background-color: #007ACC;
    display: inline-block;
    font-size: 14px;
    width: 70px;
    color: black;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 20px;
}
.other-news a{
    font-size: 12px;
}
.other-news a:hover{
    cursor: pointer;
    color: #007ACC;
}



</style>