<template>
    <div class="box">
        <headC></headC>
        <bannerC></bannerC>
        <navigationC></navigationC>
        
        <div style="height: 1000px;"></div>
        <footC></footC>
    </div>

</template>

<script>
import headC from '../components/headC.vue'
import navigationC from '../components/navigation.vue'
import bannerC from '../components/banner.vue'
import footC from '../components/footC.vue'
export default ({
    name: 'testV',
    components:{
        headC,
        navigationC,
        bannerC,
        footC,
    },
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            companyName: this.CONSTANT.companyName,
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },

        
    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
        document.title= this.companyName + " | test";


    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
.box{
    width: 100%;
}

</style>