<template>
    <div>
        <headC myId=3></headC>
        <navigationC name="Products"></navigationC>
        <div class="detail-div">
            <div class="pic-div">
                <div class="enlarged-img">
                    <img :src = "curImage" class="enlarged-img-picture">
                </div>
                <div class="thumbnail">
                    <div class="img-item" v-for="item in curProduct.images" :key="item">
                        <img class="product-img" :src="item" @mouseover="changeImg(item)">
                    </div>
                </div>
            </div>
            <div class="desc-div">
                <span class="name-title">{{ curProduct.name }}</span>
                <span class="one-line">
                    <span>CATEGORY:</span><span class="bold-span"><a @click="goProducts(curProduct.category)">{{ curProduct.category }}</a></span>
                </span>
                <span class="one-line">
                    <span>TAG:</span> <span class="bold-span">{{ curProduct.tag }}</span>
                </span>
                <div>
                    <input type="button" value="Inquiry Now" class="button" @click="scrollToView('2')"/>
                </div>
            </div>  
            <div class="desc-inquiry">
                <div class="desc-title">
                    <span :class="subPage == 1 ? 'curClass' : ''" @click="changeSubPage('1')">DESCRIPTION</span>
                    <span :class="subPage == 2 ? 'curClass' : ''" @click="changeSubPage('2')">INQUIRY</span>
                </div>
                <div id="inquiry-info" class="sub-page-div">
                    <span v-if="subPage == 1">
                        {{ curProduct.description }}
                    </span>
                    <inquiryC  v-if="subPage == 2" :name='curProduct.name' show=0></inquiryC>
                </div>
            </div>
        </div>
        <footC></footC>
    </div>
</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'
import inquiryC from '../components/inquiry.vue'
export default ({
    name: 'productDetailV',
    components: {
        headC,
        footC,
        navigationC,
        inquiryC,
    },
    data() {
        return {
            category: this.PRODUCT.productCategory,
            productList: this.PRODUCT.productList,
            curProduct: {},
            productName: '',
            curImage: '',
            subPage: 1,
        };
    },
    watch:{
        '$route.query.name': {
			immediate: true,
            deep: true,
			handler: function(name) {
				if(name != undefined){
                   this.productName = name; 
                   this.initInfo();
                }
			}
		}
    },
    
    methods:{
        initInfo(){
            for (var i = 0; i < this.productList.length; i++) {
                var curList = this.productList[i];
                for (var j = 0; j < curList.length; j++) {
                    var curObj = curList[j];
                    if(curObj.name == this.productName){
                        this.curProduct = curObj;
                        break;
                    }
                } 
            }
            this.curImage = this.curProduct.images[0];
        },
        changeImg(url){
            this.curImage = url;
        },
        goProducts(gory){
            var cateId = 0;
            for (var i = 0; i < this.category.length; i++) {
                if(this.category[i].name == gory){
                    cateId = this.category[i].id;
                }
            }
            this.$router.push({
                name:'products',
                params:{
                    pName:cateId,
                }
            });
        },
        changeSubPage(id){
            this.subPage = id;
        },
        scrollToView(id){
            this.subPage = id;
            document.getElementById('inquiry-info').scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth'
            });
        },
    },
    mounted: function(){
        if(this.productName != '' && Object.keys(this.curProduct).length === 0){
            this.initInfo();
        }
    },
})
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color:#666666;
}
a:hover {
    cursor: pointer;
    text-decoration:underline;
}
.detail-div{
    width: 100%;
}
.pic-div{
    position: relative;
    width:40%;
    padding-top: 35%;
    margin-bottom: 30px;
    float:left;
}
.enlarged-img{
    position: absolute;
    margin: 0;
    padding: 0;
    top: 5%;
    left: 20%;
    width: 65%;
    height: 65%;
}
.enlarged-img-picture{
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border: 1px solid #999999;
}
.thumbnail{
    position: absolute;
    margin: 0;
    padding: 0;
    top: 73%;
    left: 20%;
    width: 90%;
    height: 25%;
}
.img-item{
    border-radius: 5px;
    float:left;
    width:20%;
    margin-right: 5%;
    padding: 0;
    border: 2px solid #666666;
    line-height: 0px;
}
.product-img{
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 3px;
}
.desc-div{
    float:left;
    width: 56%;
    margin-left: 4%;
}
.name-title{
    display: block;
    font-size: 30px;
    font-weight: bold;
    color:#333333;
    margin-top: 1%;
    line-height: 80px;
}
.one-line{
    display: block;
    line-height: 40px;
    font-size: 16px;
    color:#999999;
}
.bold-span{
    font-weight: bold;
    color:#666666;
    margin-left: 5px;
}
.button{
    width:120px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color:#333333;
    border: none;
    border-radius: 6px;
    background-color: #eeeeee;
    margin-top: 20px;
}
.button:hover{
    cursor: pointer;
    border: 1px solid #cccccc;
}

.desc-inquiry{
    float:left;
    width:100%;
}
.desc-title{
    width:94%;
    margin-left:3%;
    border-bottom: 2px solid #CCCCCC;
}
.desc-title span{
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color:#999999;
    height: 40px;
    line-height: 40px;
    padding: 5px 0;
    
}
.desc-inquiry span:hover{
    cursor: pointer;
}
.curClass{
    color:#333333 !important;
    border-bottom: 2px solid #666666;
}
.sub-page-div{
    width: 90%;
    margin-left: 3%;
}
.sub-page-div span{
    display: inline-block;
    width: 100%;
    word-break: break-word;
    /* white-space: normal; */
    color:#666666;
    font-size: 18px;
    line-height: 40px;
    padding: 30px 20px;
    white-space: pre-wrap;
}


</style>