<template>
    <div class="all">
        <headC myId=1></headC>
        <bannerC></bannerC>
        <div class="products">
            <div class="product-title">MAIN PRODUCT</div>
        
            <div class="main-product-text">
                <span>
                    Kiusmod tempor sed incididunts labore sed dolor mag aliqua 
                    enimad mini veniam quis nostrud exercitation ullamco labores 
                    nisi ut aliquip. <br />
                    Lorem ipsum dolor tempor amety consecteur adipisicing elits do eiusmod 
                    tempor.
                </span>
            </div>
            <div :class="shrink ? 'product-list-shrink' : 'product-list'">
                <div v-for="item in productList" :key="item.name" :class="shrink ? 'product-item-shrink' : 'product-item'" 
                @mouseover="readMore(item.name)" @mouseout="readMore('')" @click="goDetail(item.name)">
                <img :src=item.icon class="product-img" >
                <span class="product-name" >{{ item.name }}</span>
                <div class="hidden-info">
                    <span  v-show="item.name == curProduct" class="read-more-product-detail" >  
                        <i-right-small  size="14px" fill="#FFFFFF"/> read more
                    </span>
                </div>
            </div>
            </div>
            
            <div class="more-info">
                <a class="contact-a" @click="changePage(3)"><span>MORE INFO</span></a>
            </div>
        </div>

        <div class="contact-now"> 
            <span class="contact-span">
                Please leave your information to us, and we are ready to help you. Call Us at +86-12345678999
                <a class="contact-a" @click="changePage(1)"><span class="button-contact">Contact Now</span></a>
            </span>
        </div>

        <div class="about-us">
            <div class="product-title">ABOUT US</div>
            <div class="about-text">
                Kiusmod tempor sed incididunts labore sed dolor mag aliqua enimad mini veniam quis nostrud exercitation ullamco labores nisi ut aliquip ex ea reprehen deritin voluptate.Lorem ipsum dolor tempor amety consecteur adipisicing elits do eiusmod tempor incididunt aliqua.Kiusmod tempor sed incididunts labore sed dolor mag aliqua enimad mini veniam quis nostrud exercitation ullamco labores nisi ut aliquip ex ea reprehen deritin voluptate.
            </div>
            <div class="read-more"><a class="contact-a" @click="changePage(2)"><span>READ MORE</span></a></div>
            <div class="about-us-image">
                <div v-for="item in usImages" :key="item.name" class="image-item">
                    <img :src=item.url class="product-img" >
                </div>
            </div>
        </div>
        
        <footC></footC>
    </div>

</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import bannerC from '../components/banner.vue'
export default ({
    name: 'homeV',
    components: {
        headC,
        footC,
        bannerC,
    },
    data() {
        return {
            shrink: false,
            companyName: this.CONSTANT.companyName,
            curProduct: '',
            productList: this.PRODUCT.homeProductList,
            usImages: this.CONSTANT.usImages,
        };
    },
    
    methods:{
        handleResize() {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 840 ? true : false;
        },
        changePage(num){
            if (num == 1) {
                this.$router.push('/contact')
            } else if (num == 2) {
                window.scrollTo(0, 0);
                this.$router.push('/about')
            }  else if (num == 3) {
                window.scrollTo(0, 0);
                this.$router.push('/products')
            }
        },
        readMore(name) {
            this.curProduct = name;
        },
        goDetail(productName) {
            window.scrollTo(0, 100);
            this.$router.push({
                name: 'productDetail',
                query: {
                    name: productName,
                },
            });
        },
    },
    mounted: function(){
        document.title= this.companyName + " | home";
        // this.$axios.post("/access").then(function (){});   
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },

})
   
</script>
<style scoped>
.all{
    width: 100%;
    margin: 0 0 0 0;
}

.contact-now{
    background-color: #007ACC;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    word-break: break-word;
    white-space: pre-wrap;
    width: 90%;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 5%;
    padding-right: 5%;
}

.contact-a:hover{
    cursor: pointer;
}
.button-contact{
    font-size: 16px;
    border: white 1px solid;
    border-radius: 5px;
    padding: 15px 15px;
    background-color: #0570b7;
    margin-left: 20px;
    display: inline-block;
}
.about-us{
    width: 100%;
    background-color: #FCFCFC;
    padding-bottom: 20px;
}
.about-text{
    width: 90%;
    margin-left: 5%;
}
.read-more{
    text-align: center;
    margin-top: 20px;
}
.read-more span{
    background-color: #007ACC;
    padding: 10PX 10PX;
    color: white;
    font-size: 16px;
}

/* 产品信息展示 */
.products {
    width: 100%;
    background-color: #FCFCFC;
    /* border: 1px solid; */
}
.product-title{
    font-size: 30px;
    text-align: center;
    padding-top: 25px;
}

.main-product-text{
    width: 90%;
    margin-left: 3%;
    text-align: center;
}
.main-product-text span{
    display: inline-block;
    width: 100%;
    word-break: break-word;
    white-space: pre-wrap;
    color:#666666;
    font-size: 18px;
    line-height: 40px;
    padding: 30px 20px;
}


.product-list{
    width: 100%;
    margin-left: 4%;
}

.product-list-shrink{
    width: 100%;
    margin-left: 6%;
}

.product-item{
    position: relative;
    float: left;
    margin-right: 4%;
    width:20%;
    margin-top: 40px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    color: #333333;
    transition: all 0.5s ; 
}

.product-item-shrink{
    position: relative;
    float: left;
    margin-right: 6%;
    width:41%;
    margin-top: 40px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    color: #333333;
    transition: all 0.5s ;
}

.product-item:hover{
    cursor: pointer;
    transform: translateY(-40px);
    transition: all 0.5s ; 
}
.product-item-shrink:hover{
    cursor: pointer;
    transform: translateY(-40px);
    transition: all 0.5s ;
}

.product-name:hover{
    color: #007ACC;
}

.product-img{
    width: 100%;
    
}

.hidden-info{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    text-align: center;
}

.more-info{
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.more-info span{
    background-color: #007ACC;
    padding: 10PX 15PX;
    color: white;
    font-size: 14px;
}

.read-more-product-detail{
    font-size: 14px;
    color: #FFFFFF;
    padding: 7px 5px 7px 5px;
    text-align: center;
    background-color: #007ACC;
}

.banner-product{
    width: 100%;
    margin-top: 10px;
    background-color: #007ACC;
    float: left;
}

.about-us-image{
    width: 90%;
    margin-left: 3%;
    margin-top: 40px;
}
.image-item{
    width: 28%;
    margin-left: 5%;
    float: left;
    margin-bottom: 40px;
    overflow: hidden;
}
.image-item img{
    cursor: pointer;
    transition: transform 0.3s ease;
}

.image-item img:hover{
    transform: scale(1.2);
}

</style>