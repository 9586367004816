<template>
    
    <div>
        <headC myId=4></headC>
        <navigationC name="News"></navigationC>
        <div class="news-box">
            <div v-for="(item, index) in newsList" :key="index" 
            :class="shrink ? 'news-item-shrink' : 'news-item'">
                <div :class="shrink ? 'news-img-shrink' : 'news-img'">
                    <img :src=item.picture >
                </div>
                <div :class="shrink ? 'news-info-shrink' : 'news-info'">
                    <div class="news-title" @click="goDetail(item.path)">
                        <h3>{{ item.title }}</h3>
                    </div>
                    <div class="news-time">
                        <span>{{ item.date }} </span>
                    </div>
                    <div class="news-text">
                        <span>{{ item.content }} </span>
                    </div>
                    <div class="news-detail" @click="goDetail(item.path)">
                        <span>MORE DETAILS</span>
                    </div>
                </div>
            </div>
        </div>
        <footC></footC>
    </div>
</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'

export default ({
    name: 'newsV',
    components: {
        headC,
        footC,
        navigationC,
    },
    data() {
        return {
            shrink: false,
            fullWidth: 0,
            fixed: false,
            companyName: this.CONSTANT.companyName,
            newsList: this.NEWS.newsList,
        };
    },
    
    methods:{
      handleResize() {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 840 ? true : false;
        },
        goDetail(path){
            window.scrollTo(0, 0);
            this.$router.push({ 
                path: path, 
            }); 
        }
    },
    mounted: function(){
        document.title= this.companyName + " | news";
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
})
   
</script>
<style scoped>
*{
    margin: 0;
    padding: 0;
}
.news-box{
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 50PX;
}

.news-item{
    background-color: #EFEFEF;
    width: 100%;
    margin-top: 50px;
    display: flex;
}
.news-item-shrink{
    background-color: #EFEFEF;
    width: 100%;
    margin-top: 50px;
}

.news-img{
    width: 30%;
    overflow: hidden;
}
.news-img-shrink{
    width: 100%;
    overflow: hidden;
}
.news-img img{
    width: 100%;
    transition: transform 0.3s ease;
}
.news-img-shrink img{
    width: 100%;
    transition: transform 0.3s ease;
}

.news-img img:hover{
    transform: scale(1.2);
}
.news-info{
    width: 60%;
    margin-left: 8%;
    word-break: break-word;
    white-space: pre-wrap;
}
.news-info-shrink{
    width: 100%;
    margin-left: 2%;
    word-break: break-word;
    white-space: pre-wrap;
}

.news-title{
    font-size: 24px;
    padding-top: 15px;
    cursor: pointer;
}
.news-time{
    padding-top: 15px;
    color:#656565;
}

.news-text{
    padding-top: 15px;
    color:#656565;
    font-size: 14px;
}
.news-detail{
    padding-top: 18px;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 20px;
}
.news-detail span{
    padding: 5px 20px 5px 20px;
    border: solid 1px #000000;
    cursor: pointer;

}

</style>