<template>
    <div>
        <headC myId=2></headC>
        <navigationC name="About Us"></navigationC>
        <div class="content-area">
            <div class="content-title">About Us</div>
            <div class="content-text">
                The company is located in Qingdao City, Shandong Province. Have many years of production experience.Our company mainly produces disinfection series products, now has an annual output of 50,000 tons of disinfection products production and processing capacity, leading products are sdic,tcca,clo2 series of disinfectant, bubble disinfection tablets, disinfection powder (granule) and other dosage form specifications of water treatment products. Disinfection products are mainly used for sterilization, disinfection, water purification, bleaching, algae killing, deodorization, has been widely used in health and epidemic prevention, food processing, animal husbandry and aquaculture, wool shrinkage prevention, industrial water treatment, swimming pool water treatment, urban sewage treatment, environmental disinfection, rubber chlorination and other industries. Our company has independent import and export rights, products are exported to more than 40 countries in the world.
            </div>
            <div class="content-title">Where We Are Going</div>
            <div class="content-text">
                Our growth will never make us lose sight of our humble beginnings. <br/>
                CARTURZBO doesn't just sell products, we market them to a huge customer base that shows world-class representation of our vendors' products. As large as we have become, the family atmosphere that has been a part of CARTURZBO will always remain. From our founders right through our newest employees, we will ensure that the passion for the automotive business, the compassion for all of our staff, and the devotion to satisfying each and every customer makes us like no other business.
            </div>
            
        </div>
        <footC></footC>
    </div>

</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'
export default ({
    name: 'aboutUsV',
    components: {
        headC,
        footC,
        navigationC,
    },
    data() {
        return {
            shrink: false,
            fullWidth: 0,
            fixed: false,
            companyName: this.CONSTANT.companyName,
        };
    },
    
    methods:{
      
    },
    mounted: function(){
        document.title= this.companyName + " | about";
    },
})
   
</script>
<style scoped>
.content-area{
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 20px;
}
.content-title{
    font-size: 30px;
    padding: 10px;
}

.content-text{
    white-space: pre-wrap;
}


</style>