import VueRouter from 'vue-router'
import Vue from 'vue'

import homeV from '../views/home.vue'
import aboutUsV from '../views/aboutUs.vue'
import productsV from '../views/products.vue'
import newsV from '../views/news.vue'
import contactUsV from '../views/contactUs.vue'
import searchV from '../views/search.vue'
import productDetailV from '../views/productDetail.vue'
import newsDetailV1 from '../views/newsDetail1.vue'
import testV from '../views/test.vue'

//1.通过vue.use(插件) 安装插件
Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'home',
        component: homeV
    },
    {
        path: '/about',
        name: 'about',
        component: aboutUsV
    },
    {
        path: '/products',
        name: 'products',
        component: productsV
    },
    {
        path: '/news',
        name: 'news',
        component: newsV
    },
    {
        path: '/contact',
        name: 'contact',
        component: contactUsV
    },
    {
        path: '/search',
        name: 'search',
        component: searchV
    },
    {
        path: '/products/detail',
        name: 'productDetail',
        component: productDetailV
    },
    {
        path: '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
        name: 'newsDetail1',
        component: newsDetailV1
    },
    {
        path: '/',
        name: '/',
        component: homeV
    },

    {
        path: '/test',
        name: 'test',
        component: testV
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router

