const product_variable = {}

product_variable.productCategory = [
    { 'name': 'CTEGORY NUMBER1', 'id': 0 },
    { 'name': 'CATEGORY TCCA', 'id': 1 },
    { 'name': 'CATEGORY HAHAHA', 'id': 2 }
];

product_variable.productList = [
    [
        {
            'icon': require('../assets/images/products/tcc1.jpg'),
            'name': 'djeisjs-1', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
            'description': '    qqqqqqqqqqqqqqqqqqqvgbgfdefrfdvrfvc derfvcdfv cxsdvf cdqqqqqqqqqqqqqqqqqqqqqqqq' +
                'qqssssddddddddddddddddddddddsssssssssssssssdededewftrfdethbv56tr45yhnbgvfr567ujhnbgvfryjhnbvfssssssssss' +
                'sssssssssssssssseeeeeeeeeeeeeeeeeeeeqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq\n    qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images': [
                require('../assets/images/products/tcc1.jpg'),
                require('../assets/images/products/tcc2.jpg'),
                require('../assets/images/products/tcc3.jpg'),
            ]
        },
        {
            'icon': require('../assets/images/products/tcca1.jpg'),
            'name': 'product1-2', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
            'description': 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images': [
                require('../assets/images/products/tcca1.jpg'),
                require('../assets/images/products/tcca2.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'edeecec-3', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
            'description': 'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'product1-4', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
            'description': 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'cedef44-5', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
    [
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'dcfewcaz-1', 'category': 'CATEGORY TCCA', 'tag': 'TAG456',
            'description': 'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'edfe32wsw-2', 'category': 'CATEGORY TCCA', 'tag': 'TAG456',
            'description': 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'de3ewsq-3', 'category': 'CATEGORY TCCA', 'tag': 'TAG456',
            'description': 'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'vrt5t7urwesa-4', 'category': 'CATEGORY TCCA', 'tag': 'TAG456',
            'description': 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'crf4re2sdxs-5', 'category': 'CATEGORY TCCA', 'tag': 'TAG456',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
    [
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'dedede22wsw-1', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'ed3dcedf45-2', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'crf4r3edws-3', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'fr43esw2wsd-4', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'f4edwe2ssxdd-5', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': '67uygwedc', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'g45tgvrewf4', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon': require('../assets/images/products/sdic.jpg'),
            'name': 'njt786tgv3', 'category': 'CATEGORY HAHAHA', 'tag': 'TAG789',
            'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images': [
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
]

product_variable.homeProductList = [
    {
        'icon': require('../assets/images/products/tcc1.jpg'),
        'name': 'djeisjs-1', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': '    qqqqqqqqqqqqqqqqqqqvgbgfdefrfdvrfvc derfvcdfv cxsdvf cdqqqqqqqqqqqqqqqqqqqqqqqq' +
            'qqssssddddddddddddddddddddddsssssssssssssssdededewftrfdethbv56tr45yhnbgvfr567ujhnbgvfryjhnbvfssssssssss' +
            'sssssssssssssssseeeeeeeeeeeeeeeeeeeeqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq\n    qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
        'images': [
            require('../assets/images/products/tcc1.jpg'),
            require('../assets/images/products/tcc2.jpg'),
            require('../assets/images/products/tcc3.jpg'),
        ]
    },
    {
        'icon': require('../assets/images/products/tcca1.jpg'),
        'name': 'product1-2', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
        'images': [
            require('../assets/images/products/tcca1.jpg'),
            require('../assets/images/products/tcca2.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'edeecec-3', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'product1-4', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'cedef44-5', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'cedef44-6', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'cedef44-7', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    },
    {
        'icon': require('../assets/images/products/sdic.jpg'),
        'name': 'cedef44-8', 'category': 'CTEGORY NUMBER1', 'tag': 'TAG123',
        'description': 'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
        'images': [
            require('../assets/images/products/sdic.jpg'),
            require('../assets/images/products/sdic.jpg')
        ]
    }
]

export default product_variable