<template>
    
    <div>
        <headC myId=5></headC>
        <navigationC name="Contact Us"></navigationC>
        <div :class="!shrink? 'contact-box-210' : 'contact-box-840'">
            <div class="box-wrapper">
                <div v-for="(item,index) in contactBox" :key="index" :class="shrink? 'item-wrapper-100' : 'item-wrapper-25'">
                    <div class="box-item">
                        <span class="box-title">{{item.title}}</span>
                    <span class="box-content">{{item.value}}</span>
                    </div>
                </div>
            </div>
        </div>
        <inquiryC name="" show=1></inquiryC>
        <div class="map">
            <iframe class="map-content" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3199.677268184127!2d116.98623007571315!3d36.682259972281045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35c205f92fbde14d%3A0x91867c1aa3af296e!2z5Lit546v6Iqx5Zut!5e0!3m2!1sen-us!2shk!4v1719468220403!5m2!1sen-us!2shk" 
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
        <footC></footC>
    </div>
</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'
import inquiryC from '../components/inquiry.vue'
export default ({
    name: 'contactUsV',
    components: {
        headC,
        footC,
        navigationC,
        inquiryC,
    },
    data() {
        return {
            shrink: false,
            companyName: this.CONSTANT.companyName,
            contactBox: this.CONSTANT.contactBox,
        };
    },
    
    methods:{
        handleResize () {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 840 ? true : false;
        },
    },
    mounted: function(){
        document.title= this.companyName + " | contact";
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
})
   
</script>
<style scoped>
.contact-box-210{
    width: 100%;
    height: 210px;
    background-color: #E2E2E2;
}
.contact-box-840{
    width: 100%;
    height: 840px;
    background-color: #E2E2E2;
}

.box-wrapper{
    width: 92%;
    margin-left: 4%
}
.item-wrapper-25{
    width:25%;
    float:left;
    
}
.item-wrapper-100{
    width:100%;
    float:left;
}

.box-item{
    height: 120px;
    width:80%;
    margin-left: 10%;
    text-align: center;
    margin-top: 50px;
    background-color: #FCFCFC;
    border-radius: 10PX;
    margin-bottom: 40px;
}
.box-title{
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    display: block;
}
.box-content{
    font-size: 14px;
    color: #333333;
    display: block;
}
.map{
    position: relative;
    width:94%;
    margin-left: 3%;
    padding-top: 30%;
    margin-bottom: 40px;
}
.map-content{
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border:0
}

</style>