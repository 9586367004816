<template>
    <div>
        <headC myId=3></headC>
        <navigationC name="Products"></navigationC>
        <div class="productArea">
            <div class="dropdown" v-if="shrink">
                <select v-model="curCategory">
                    <option v-for="item in category" :key="item.id" :value="item.id" >
                        {{item.name}}
                    </Option>
                </select>
            </div>
            <div class="left-list" v-if="!shrink">
                <span class="title-span">CATEGORIES</span>
                <span v-for="item in category" :key="item.id" @click="changeCategory(item.id)" class="category-span" :class="curCategory == item.id ? 'curLine' : ''">
                    {{item.name}}
                </span>
            </div>
            <div class="right-list" :class=" shrink ? 'shirnk-width' : '' ">
                <div class="title-div" v-if="!shrink">
                    <span>PRODUCTS</span>
                </div>

                <div v-for="item in curList" :key="item.name" :class="shrink ? 'product-item-shrink' : 'product-item'"
                    @mouseover="readMore(item.name)" @mouseout="readMore('')" @click="goDetail(item.name)">
                    <img :src=item.icon class="product-img" >
                    <span class="product-name" >{{ item.name }}</span>
                    <div class="hidden-info">
                        <span  v-show="item.name == curProduct" class="read-more-product-detail" >  
                            <i-right-small  size="14px" fill="#FFFFFF"/> read more
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <footC></footC>
    </div>

</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'
export default ({
    name: 'productsV',
    components: {
        headC,
        footC,
        navigationC,
    },
    data() {
        return {
            shrink: false,
            companyName: this.CONSTANT.companyName,
            category: this.PRODUCT.productCategory,
            productList: this.PRODUCT.productList,
            curCategory: '',
            curList: [],
            curProduct: '',
        };
    },
    watch: {
		'$route.params.pName': {
			immediate: true,
            deep: true,
			handler: function(pName) {
				if(pName != undefined){
                    this.curCategory = pName;
                    this.curList = this.productList[pName];
                }
			}
		},
        curCategory(val){
            this.curList = this.productList[val];
        },
	},
    methods:{
        handleResize () {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 920 ? true : false;
        },
        changeCategory(cate){
            this.curCategory = cate;
        },
        readMore(name){
            this.curProduct = name;
        },
        goDetail (productName) {
            this.$router.push({
                name:'productDetail',
                query:{
                    name:productName,
                },
            });
        }
    },
    mounted: function(){
        document.title= this.companyName + " | products";
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        if(this.curCategory === ''){
            this.changeCategory(this.category[0].id);
        }
    },

})
   
</script>
<style scoped>
.productArea{
    width:100%;
}
.dropdown{
    width: 60%;
    margin-left: 3%;
    height: 50px;
    padding-top: 20px;
    position: relative;
    margin-bottom: 40px;
}
select{
    border: 2px solid #CCCCCC;
    outline: none;
    width: 96%;
    margin-left: 2%;
    height: 50px;
    line-height: 50px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 10px;
    font-size: 20px;
    color: #666666;
    background: url('../assets/images/products/arrow_down.png') 98% center  no-repeat;
    background-size: 30px;
}
.left-list{
    width: 20%;
    margin-left: 3%;
    float: left;
}
.title-span{
    display: block;
    font-size: 20px;
    color:#333333;
    line-height: 55px;
    border-bottom: 2px solid #CCCCCC;
}
.category-span{
    height: 32px;
    line-height: 32px;
    display: block;
    font-size: 16px;
    margin: 10px 0px;
    padding: 0px 20px 0px 0px;
    color:#333333;
}
.category-span:hover{
    cursor: pointer;
    color: #007ACC;
}
.curLine{
    color: #007ACC;
}

.right-list{
    width:74%;
    margin-right: 3%;
    float:right;
}
.shirnk-width{
    width: 96% !important;
    margin-right: 2% !important;

}
.title-div{
    width:97%;
    margin-left: 3%;
    font-size: 20px;
    color:#333333;
    line-height: 55px;
    border-bottom: 2px solid #CCCCCC;
    margin-bottom: 20px;
}

.product-item{
    position: relative;
    float: left;
    margin-left: 4%;
    width:20%;
    margin-top: 40px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    color: #333333;
    transition: all 0.5s ; 
}
.product-item-shrink{
    position: relative;
    float: left;
    margin-left: 6%;
    width:41%;
    margin-top: 40px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    color: #333333;
    transition: all 0.5s ;
}

.product-item:hover{
    cursor: pointer;
    transform: translateY(-40px);
    transition: all 0.5s ; 
}
.product-item-shrink:hover{
    cursor: pointer;
    transform: translateY(-40px);
    transition: all 0.5s ;
}

.product-name:hover{
    color: #007ACC;
}

.hidden-info{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    text-align: center;
}
.read-more-product-detail{
    font-size: 14px;
    color: #FFFFFF;
    padding: 7px 5px 7px 5px;
    text-align: center;
    background-color: #007ACC;
}

.product-img{
    width: 100%;
}

.shadow-img{
    box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
}
.icon-right{
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    right:10px;
    top: 10px; 
}
.icon-right:hover{
    cursor: pointer;
}



</style>