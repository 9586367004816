const news_variable = {}


news_variable.newsList = [
    {
        'path': '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
        'title': 'Road trippers should take an extra-long test drive',
        'picture': require('../assets/images/products/tcc1.jpg'),
        'date': '2018-08-07',
        'content': "If you're looking for a new car, here's a little advice from your fellow travelers: Take your time. I mean, really take your time. Test drive the vehicle a few extra miles, or hundreds of miles, to make sure the designers didn't take the day off when they"
    },
    {
        'path': '/news/detail/2best-gifts-for-the-car-lover-on-your-shopping-list',
        'title': 'BEST GIFTS FOR THE CAR LOVER ON YOUR SHOPPING LIST',
        'picture': require('../assets/images/products/tcc2.jpg'),
        'date': '2018-08-07',
        'content': "If you're looking for a new car, here's a little advice from your fellow travelers: Take your time. I mean, really take your time. Test drive the vehicle a few extra miles, or hundreds of miles, to make sure the designers didn't take the day off when they"
    },
    {
        'path': '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
        'title': 'Road trippers should take an extra-long test drive',
        'picture': require('../assets/images/products/tcc3.jpg'),
        'date': '2018-08-07',
        'content': "If you're looking for a new car, here's a little advice from your fellow travelers: Take your time. I mean, really take your time. Test drive the vehicle a few extra miles, or hundreds of miles, to make sure the designers didn't take the day off when they"
    },
    {
        'path': '/news/detail/survey-finds-75-percent-of-americans-cautious-about-autonomous-vehicles',
        'title': 'Survey Finds 75 Percent of Americans Cautious About Autonomous Vehicles',
        'picture': require('../assets/images/products/sdic.jpg'),
        'date': '2018-08-07',
        'content': "If you're looking for a new car, here's a little advice from your fellow travelers: Take your time. I mean, really take your time. Test drive the vehicle a few extra miles, or hundreds of miles, to make sure the designers didn't take the day off when they"
    },
]

news_variable.shareList = [
    { "name": 'ri:facebook-fill', "title": 'Facebook', "url": 'http://www.baidu.com', "color": "#5595EA" },
    { "name": 'ri:linkedin-fill', "title": 'LinkedIn', "url": 'http://www.baidu.com', "color": "#3B67BB" },
    { "name": 'ri:youtube-fill', "title": 'Youtube', "url": 'http://www.baidu.com', "color": "#E13723" },
    { "name": 'ri:instagram-fill', "title": 'Instagram', "url": 'http://www.baidu.com', "color": "#724C3D" },
    { "name": 'ri:wechat-fill', "title": 'WeChat', "url": 'http://www.baidu.com', "color": "#1CD46C" },
    { "name": 'ri:whatsapp-fill', "title": 'WhatsApp', "url": 'http://www.baidu.com', "color": "#78C468" }
];

news_variable.newsNO1 = {
    'title': 'Road trippers should take an extra-long test drive',
    'dateYear': 'Aug.2018',
    'dateDay': '07',
    'nextTitle': 'BEST GIFTS FOR THE CAR LOVER ON YOUR SHOPPING LIST',
    'nextPath': '/news/detail/2best-gifts-for-the-car-lover-on-your-shopping-list',
    'prevTitle': '',
    'prevPath': '',
}

news_variable.newsNO2 = {
    'title': 'BEST GIFTS FOR THE CAR LOVER ON YOUR SHOPPING LIST',
    'dateYear': 'Aug.2018',
    'dateDay': '07',
    'nextTitle': 'Road trippers should take an extra-long test drive',
    'nextPath': '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
    'prevTitle': 'Road trippers should take an extra-long test drive',
    'prevPath': '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
}

news_variable.newsNO3 = {
    'title': 'Road trippers should take an extra-long test drive',
    'dateYear': 'Aug.2018',
    'dateDay': '07',
    'nextTitle': 'Survey Finds 75 Percent of Americans Cautious About Autonomous Vehicles',
    'nextPath': '/news/detail/survey-finds-75-percent-of-americans-cautious-about-autonomous-vehicles',
    'prevTitle': 'BEST GIFTS FOR THE CAR LOVER ON YOUR SHOPPING LIST',
    'prevPath': '/news/detail/best-gifts-for-the-car-lover-on-your-shopping-list',
}

news_variable.newsNO4 = {
    'title': 'Survey Finds 75 Percent of Americans Cautious About Autonomous Vehicles',
    'dateYear': 'Aug.2018',
    'dateDay': '07',
    'nextTitle': '',
    'nextPath': '',
    'prevTitle': 'Road trippers should take an extra-long test drive',
    'prevPath': '/news/detail/road-trippers-should-take-an-extra-long-test-drive',
}



export default news_variable